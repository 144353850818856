import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Scrollchor from 'react-scrollchor';

// Assets
import CapConseilLogo from '../images/cap-conseil_logo.svg';
import CapConseilLogoWhite from '../images/cap-conseil_logo_white.svg';
import IconMenu from '../images/icon_menu.svg';
import IconClose from '../images/icon_close.svg';


export default class HeaderMobile extends React.Component {
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
  }

  toggleMenu () {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  }

  render () {
    const {data} = this.props;

    return (
      <div className={`header_mobile ${this.state.isOpen ? "isOpen" : ""}`}>
        <div className="header_mobile__wrapper">
          <div className="header_mobile__logo">
            <Link to="/">
              <img src={this.state.isOpen ? CapConseilLogoWhite : CapConseilLogo} alt="Cap Conseil Logo" />
            </Link>
          </div>

          <div className="header_mobile__button">
            <div onClick={() => this.toggleMenu()}><img src={this.state.isOpen ? IconClose : IconMenu} /></div>
          </div>
        </div>

        <div className="header_mobile__menu">
          <ul>
            <li>
              <Scrollchor 
                animate={{offset: -48, duration: 750 }} 
                to="#methodes" 
                beforeAnimate={() => this.toggleMenu()}
                className="text-white">Méthodes</Scrollchor>
            </li>
            <li>
              <Scrollchor 
                animate={{offset: -48, duration: 750 }} 
                to="#values" 
                beforeAnimate={() => this.toggleMenu()}
                className="text-white">Valeurs</Scrollchor>
            </li>
            <li>
              <Scrollchor 
                animate={{offset: -48, duration: 750 }} 
                to="#team" 
                beforeAnimate={() => this.toggleMenu()}
                className="text-white">Équipe</Scrollchor>
            </li>
            <li>
              <Scrollchor 
                animate={{offset: -48, duration: 750 }} 
                to="#activity" 
                beforeAnimate={() => this.toggleMenu()}
                className="text-white">Activité</Scrollchor>
            </li>
            <li>
              <Scrollchor 
                animate={{offset:0, duration: 750 }} 
                to="#contact"
                beforeAnimate={() => this.toggleMenu()} 
                className="text-white">Contact</Scrollchor>
            </li>
          </ul>
        </div>

        <div className="header_mobile__contact">
          <p className="text-white">contact@cap-conseil.fr</p>
          <p className="text-white">01 55 30 01 57</p>
        </div>

      </div>
    )
  }
}


