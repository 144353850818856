import React from "react"
import PropTypes from "prop-types"
import { ParallaxProvider } from 'react-scroll-parallax';

import Header from "../components/header"
import HeaderMobile from "../components/header_mobile"
import "./main.scss"


const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <HeaderMobile />
      <ParallaxProvider>
        <div className="site-wrapper">
          {children}
        </div>
      </ParallaxProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;

