import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Scrollchor from 'react-scrollchor';

// Assets
import CapConseilLogo from '../images/cap-conseil_logo.svg'

const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="header__wrapper">
      <div className="header__logo">
        <Link to="/">
          <img src={CapConseilLogo} alt="Cap Conseil Logo" />
        </Link>
      </div>

      <div className="header__menu">
        <ul>
          <li>
            <Scrollchor animate={{offset: -80, duration: 750 }} to="#methodes">Méthodes</Scrollchor>
          </li>
          <li>
            <Scrollchor animate={{offset: -80, duration: 750 }} to="#values">Valeurs</Scrollchor>
          </li>
          <li>
            <Scrollchor animate={{offset: -80, duration: 750 }} to="#team">Équipe</Scrollchor>
          </li>
          <li>
            <Scrollchor animate={{offset: -80, duration: 750 }} to="#activity">Activité</Scrollchor>
          </li>
          <li>
            <Scrollchor animate={{offset:0, duration: 750 }} to="#contact">Contact</Scrollchor>
          </li>
        </ul>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;

